import React, { useEffect, useMemo, useState } from 'react'

import { MessageHeader, MessageContent, Message, Icon } from 'semantic-ui-react'

import FieldWrapper from '../FieldWrapper/FieldWrapper'

import './InfoPanel.scss'


const InfoPanel = (props) => {
    const { type, title, text, ...styleProps } = props

    const infoType = useMemo(() => type || 'info', [type])

    const icon = useMemo(() => ({
        info: 'info circle',
        warning: 'warning sign'
    }[infoType]), [infoType])

    const messageProps = useMemo(() => ({
        [infoType]: true
    }), [infoType])

    return (
        <Message icon {...messageProps} className='cb-form-info-panel'>
            <Icon name={icon} />
            <MessageContent>
                <MessageHeader>
                    {title}
                </MessageHeader>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </MessageContent>
        </Message>
    )
}

export default InfoPanel